import { browser } from '$app/environment';
import { globals } from '../utils';

export function load() {
	// This is how we redirect fixee.ai to app.fixee.ai for now, remove this when the wordpress site is up on fixee.ai
	// It's easier to redirect from here, and temporary
	if (browser) {
		const origin = window.location.origin;
		const url = window.location.href;

		let newUrl;
		if (origin.includes('://fixee.ai')) {
			newUrl = url.replace('fixee.ai', 'app.fixee.ai');
		}
		if (origin.includes('://staging.fixee.ai')) {
			newUrl = url.replace('staging.fixee.ai', 'staging.app.fixee.ai');
		}
		if (newUrl) {
			window.location.href = newUrl;
		}

		// Save the original reference of the history methods
		const originalPushState = history.pushState;
		const originalReplaceState = history.replaceState;

		// Extend pushState to dispatch a custom event
		history.pushState = function () {
			// eslint-disable-next-line prefer-rest-params
			originalPushState.apply(this, arguments as any);
			// eslint-disable-next-line prefer-rest-params
			const event = new CustomEvent('pushState', { detail: arguments });
			window.dispatchEvent(event);
		};
		// Extend replaceState to dispatch a custom event
		history.replaceState = function () {
			// eslint-disable-next-line prefer-rest-params
			originalReplaceState.apply(this, arguments as any);
			// eslint-disable-next-line prefer-rest-params
			const event = new CustomEvent('replaceState', { detail: arguments as any });
			window.dispatchEvent(event);
		};

		window.addEventListener('popstate', () => {
			globals.appHistory.pop();
		});

		window.addEventListener('pushState', (e: any) => {
			globals.appHistory.push(e.detail[2].pathname);
		});
	}
}
