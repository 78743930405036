<script lang="ts">
	import { _ } from 'svelte-i18n';
	import { getGlobalVarsContext } from '../../../routes/contexts';
	import { isTouchScreen } from '../../../utils';

	const globalVars = getGlobalVarsContext();

	const localStorageVideoPermissionsWarningKey = 'videoPermissionsWarningHasBeenShownOnce';

	// We don't want to shwo this more than once because on Firefox for example, we can't get the permission status for camera or microphone.
	// So we would have to show this warning every time which does not look good
	$: if ($globalVars.openVideoPermissionsWarning) {
		// The timeout is important so the rendering is done before we set the localStorage item, otherwise the rendering would be prevented
		setTimeout(() => {
			window.localStorage.setItem(localStorageVideoPermissionsWarningKey, 'true');
		});
	}
</script>

{#if $globalVars.openVideoPermissionsWarning && !window.localStorage.getItem(localStorageVideoPermissionsWarningKey)}
	<div
		class="absolute z-[20] bg-white top-0 right-0 bottom-0 left-0 flex flex-col gap-4 py-10 items-center {isTouchScreen()
			? 'justify-end'
			: 'justify-center'} p-4"
	>
		<img src="/svgs/allow_video.svg" alt="empty" class="" />

		<AtomText type="bodyBold" class="text-center flex">
			{$_('permissionsAuthorizeWarning.title')}
			<p class="underline ml-1">{$_('permissionsAuthorizeWarning.titleAuthorize')}</p>
		</AtomText>
		<AtomText type="caption" color="secondary" class="text-center w-[300px]">
			{$_('permissionsAuthorizeWarning.description')}
		</AtomText>

		<AtomText
			type="caption"
			color="secondary-darker"
			class="text-center w-[300px] !cursor-pointer hover:!text-secondary-700"
			underline
			on:click={() => {
				$globalVars.openVideoPermissionsWarning = false;
			}}
		>
			{$_('permissionsAuthorizeWarning.close')}
		</AtomText>
	</div>
{/if}
