<script lang="ts">
	import type { Notification, Team } from 'fixee-server';
	import type { NotificationType } from 'fixee-server/lib/services/notification/notification.shared';
	import { onDestroy, onMount, setContext } from 'svelte';
	import { writable } from 'svelte/store';
	import useFixeeClient from '../../../api/fixee-client';
	import { inDevelopment } from '../../../env';
	import {
		getApiStatusContext,
		getNotificationsContext,
		getNotificationsInfosContext,
		type NotificationsInfos
	} from '../../../routes/contexts';
	import { sentryCaptureException } from '../component-functions';

	export let currentTeam: Team | undefined;

	setContext(
		'notificationsInfos',
		writable<NotificationsInfos>({
			open: false,
			loading: false,
			nbUnseen: 0,
			nbUnseenIncomingPortals: 0,
			filterHasBeenSeen: undefined,
			types: undefined,
			hasLoadedAllTheNotifications: false,
			reloadNotifications: (notificationIds: string) => {
				reloadNotifications(notificationIds);
			},
			loadNotifications: (
				skip: number,
				filterHasBeenSeen: undefined | boolean,
				types?: NotificationType[]
			) => {
				loadNotifications(skip, filterHasBeenSeen, types);
			}
		})
	);
	setContext('notifications', writable([]));
	const notifications = getNotificationsContext();
	const notificationsInfos = getNotificationsInfosContext();
	const apiStatus = getApiStatusContext();

	// We make sure to trigger this only when the team id changes, not if the team object changes for the same team (like when we load the site for example et the team object is updated)
	$: currentTeamId = currentTeam?.id;
	// Do this to prevent multiple calls, each time the object is updated. We can't use "$notificationsInfos.filterHasBeenSeen" directly
	$: filterHasBeenSeen = $notificationsInfos.filterHasBeenSeen;
	$: types = $notificationsInfos.types;
	$: if ($apiStatus === 'authenticated' && currentTeamId) {
		loadNotifications(0, filterHasBeenSeen, types);
		loadUnseenNotifications();
	}

	const reloadNotifications = async (notificationIds: string[]) => {
		if (!currentTeam) return;

		try {
			const loadedNotifications = await useFixeeClient()
				.service('notification')
				.find({
					query: {
						teamId: currentTeam.id,
						id: {
							$in: notificationIds
						}
					}
				});

			$notifications = $notifications.map((n) => {
				const foundNotification = loadedNotifications.data.find((ln) => ln.id === n.id);
				return foundNotification || n;
			});
		} catch (error) {
			sentryCaptureException(error);
			console.log('reloadNotification error', error);
		}
	};

	const loadNotifications = async (
		skip: number,
		filterHasBeenSeen: undefined | boolean,
		types?: NotificationType[]
	) => {
		// We reset stuff when we search from the start again
		if (skip === 0) {
			$notifications = [];
			$notificationsInfos.hasLoadedAllTheNotifications = false;
		}

		if (!currentTeam) return;
		if ($notificationsInfos.hasLoadedAllTheNotifications) return;
		if ($notificationsInfos.loading) return;

		$notificationsInfos.filterHasBeenSeen = filterHasBeenSeen;
		$notificationsInfos.loading = true;
		try {
			const loadedNotifications = await useFixeeClient()
				.service('notification')
				.find({
					query: {
						teamId: currentTeam.id,
						$limit: inDevelopment() ? 12 : 20,
						$skip: skip,
						$sort: {
							createdAt: -1
						},
						hasBeenSeen: filterHasBeenSeen,
						...(types && {
							type: {
								$in: types
							}
						})
					}
				});

			$notifications = [...$notifications, ...loadedNotifications.data];

			if ($notifications.length >= loadedNotifications.total) {
				$notificationsInfos.hasLoadedAllTheNotifications = true;
			}
		} catch (error) {
			sentryCaptureException(error);
			console.log('loadNotifications error', error);
		}

		$notificationsInfos.loading = false;
	};

	const loadUnseenNotifications = async () => {
		if (!currentTeam) return;
		try {
			const unreadNotifications = await useFixeeClient()
				.service('notification')
				.find({
					query: {
						teamId: currentTeam.id,
						$limit: 0,
						hasBeenSeen: false
					}
				});
			$notificationsInfos.nbUnseen = unreadNotifications.total;

			const unreadIncomingPortalsNotifications = await useFixeeClient()
				.service('notification')
				.find({
					query: {
						teamId: currentTeam.id,
						$limit: 0,
						hasBeenSeen: false,
						type: 'portal_invitation_to_take_charge'
					}
				});
			$notificationsInfos.nbUnseenIncomingPortals = unreadIncomingPortalsNotifications.total;
		} catch (error) {
			sentryCaptureException(error);
			console.log('loadUnseenNotifications error', error);
		}
	};

	const onNotificationCreated = (notification: Notification) => {
		if (notification.teamId !== currentTeam?.id) return;

		loadUnseenNotifications();

		$notifications = [notification, ...$notifications];
	};
	const onNotificationPatched = (notification: Notification) => {
		if (notification.teamId !== currentTeam?.id) return;

		loadUnseenNotifications();

		$notifications = $notifications.map((n) => (n.id === notification.id ? notification : n));
	};

	onMount(() => {
		useFixeeClient().service('notification').on('created', onNotificationCreated);
		useFixeeClient().service('notification').on('patched', onNotificationPatched);
	});
	onDestroy(() => {
		useFixeeClient().service('notification').off('created', onNotificationCreated);
		useFixeeClient().service('notification').off('patched', onNotificationPatched);
	});
</script>

<slot />
