<script lang="ts">
	import { _ } from 'svelte-i18n';
	import { getGlobalVarsContext } from '../../../routes/contexts';
	import AtomText from '../atoms/AtomText.svelte';
	import MolModal from './MolModal.svelte';

	const globalVars = getGlobalVarsContext();

	let open = false;
	$: if ($globalVars.openComingSoonModal) {
		open = true;
	}

	const close = () => {
		open = false;
		// For a better UI experience, we wait for the modal to close
		setTimeout(() => {
			$globalVars.openComingSoonModal = undefined;
		}, 200);
	};
</script>

<img src="/svgs/available_soon.svg" alt="empty" class="hidden" />

<MolModal
	bind:open
	title={$_(`modals.${$globalVars.openComingSoonModal}.title`)}
	firstButton={{
		action: () => close(),
		text: $_(`modals.${$globalVars.openComingSoonModal}.firstButton`),
		variant: 'default'
	}}
>
	<div>
		<!-- We do this padding trick to keep the aspect ratio of the image event when it's loading at
		first. Without this there is an ugly flash of the image when it's loading. -->
		<div class="h-0 overflow-hidden relative pt-[66%]">
			<img
				src="/svgs/available_soon.svg"
				alt="empty"
				class="absolute top-0 bottom-0 left-0 right-0"
			/>
		</div>

		<AtomText color="secondary-darker" class="text-center">
			{$_(`modals.${$globalVars.openComingSoonModal}.description`)}
		</AtomText>
		<AtomText color="secondary-darker" class="text-center">
			{$_(`modals.${$globalVars.openComingSoonModal}.description2`, { default: '' })}
		</AtomText>
	</div>
</MolModal>
