<script lang="ts">
	import type { Tag, Team } from 'fixee-server';
	import { onDestroy, onMount, setContext } from 'svelte';
	import { writable } from 'svelte/store';
	import useFixeeClient from '../../../api/fixee-client';
	import { getApiStatusContext, getTagsContext } from '../../../routes/contexts';
	import { sentryCaptureException } from '../component-functions';

	export let currentTeam: Team | undefined;

	setContext('tags', writable([]));
	const tags = getTagsContext();
	const apiStatus = getApiStatusContext();

	const loadTags = async (skip = 0) => {
		if (!currentTeam) return;
		try {
			const loadedTags = await useFixeeClient()
				.service('tag')
				.find({
					query: {
						teamId: currentTeam.id,
						$limit: 200,
						$skip: skip,
						$sort: {
							createdAt: 1
						}
					}
				});
			$tags = [...$tags, ...loadedTags.data];
			if (loadedTags.total > $tags.length) await loadTags($tags.length);
		} catch (error) {
			sentryCaptureException(error);
			console.log('loadTags error', error);
		}
	};

	// We make sure to trigger this only when the team id changes, not if the team object changes for the same team (like when we load the site for example et the team object is updated)
	$: currentTeamId = currentTeam?.id;
	$: if ($apiStatus === 'authenticated' && currentTeamId) {
		$tags = [];
		loadTags();
	}

	const onTagCreated = (tag: Tag) => {
		if (tag.teamId !== currentTeam?.id) return;

		$tags = [...$tags, tag];
	};
	const onTagPatched = (tag: Tag) => {
		if (tag.teamId !== currentTeam?.id) return;

		$tags = $tags.map((t) => (t.id === tag.id ? tag : t));
	};
	const onTagRemoved = (tag: Tag) => {
		if (tag.teamId !== currentTeam?.id) return;

		$tags = $tags.filter((t) => t.id !== tag.id);
	};

	onMount(() => {
		useFixeeClient().service('tag').on('created', onTagCreated);
		useFixeeClient().service('tag').on('patched', onTagPatched);
		useFixeeClient().service('tag').on('removed', onTagRemoved);
	});
	onDestroy(() => {
		useFixeeClient().service('tag').off('created', onTagCreated);
		useFixeeClient().service('tag').off('patched', onTagPatched);
		useFixeeClient().service('tag').off('removed', onTagRemoved);
	});
</script>

<slot />
