<script lang="ts">
	import type { PersistentStore } from '$lib/svelte-persistent-store-main/src/index';
	import type { Credit, Subscription, Team } from 'fixee-server';
	import { onDestroy, onMount } from 'svelte';
	import type { Writable } from 'svelte/store';
	import useFixeeClient from '../../../api/fixee-client';
	import { loadMyTeams } from '../component-functions';

	export let teams: Writable<Team[]>;
	export let currentTeam: PersistentStore<Team | undefined>;
	export let currentSubscription: PersistentStore<Subscription | undefined>;

	const onCreditCreated = (credit: Credit) => {
		if (credit.teamId !== $currentTeam?.id) return;

		$currentTeam.credits = credit.total;
	};

	// Use SubscriptionAddValues instead of any, when we update the server
	const onSubscriptionAddValues = (data: any) => {
		if (data.subscriptionId !== $currentSubscription?.id) return;

		if ($currentSubscription) {
			$currentSubscription.nbSmsSent += data.addNbSms;
			$currentSubscription.nbLiveMinutesUsed += data.addNbLiveMinutes;
			$currentSubscription = $currentSubscription; // We do this to trigger a refresh
		}
	};

	const reloadSubscription = async () => {
		$teams = await loadMyTeams();
		$currentTeam = $teams.find((team) => team.id === $currentTeam?.id); // Changing the team will trigger the change of subscription in the main layout
	};

	onMount(() => {
		useFixeeClient().service('credit').on('created', onCreditCreated);
		useFixeeClient().service('subscription').on('addValues', onSubscriptionAddValues);
		useFixeeClient().service('subscription').on('patched', reloadSubscription);
		useFixeeClient().service('subscription').on('created', reloadSubscription);
	});
	onDestroy(() => {
		useFixeeClient().service('subscription').off('created', reloadSubscription);
		useFixeeClient().service('subscription').off('patched', reloadSubscription);
		useFixeeClient().service('subscription').off('addValues', onSubscriptionAddValues);
		useFixeeClient().service('credit').off('created', onCreditCreated);
	});
</script>

<slot />
